import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "../pages/Home";
import Login from "../pages/login";
import Signup from "../pages/signup";
import Contact from "../pages/Contact/index";
import About from "../pages/About";
import Service from "../pages/Service";
import Pricing from "../pages/Pricing";
import Stripe from "../pages/stripe";
import Thankyou from "../pages/thankyou";
import Otp from "../pages/Otp";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import PrivacyPage from "../pages/Privacy-Policy";
import ScrollToTop from "components/ScrollToTop";

export const PublicRoutes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/services/:type?" element={<Service />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup/:type?" element={<Signup />} />
        <Route path="/send-otp" element={<Otp />} />
        <Route path="/pricing" element={<Pricing />} />

        <Route path="/stripe" element={<Stripe />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/terms-and-conditions" element={<PrivacyPolicy />} />
        <Route path="/terms/conditions" element={<PrivacyPage />} />
      </Routes>
    </Router>
  );
};
