import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const FloatingButton = () => {
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [scrollHeightButton, setScrollHeightButton] = useState(false);
  const { t } = useTranslation();

  // Handle scroll position
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY; // Get current scroll position
      const scrollHeight = document.documentElement.scrollHeight; // Get total scrollable height
      const clientHeight = window.innerHeight; // Get the viewport height

      // Check if the user has scrolled more than 50px
      if (scrollTop > 50) {
        setScrollHeightButton(true);
      } else {
        setScrollHeightButton(false);
      }

      // Check if the user has scrolled to the bottom
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        setIsAtBottom(true);
      } else {
        setIsAtBottom(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Link to="/pricing">
        <button className={`floating-button ${isAtBottom ? 'bottom' : 'top'} ${scrollHeightButton ? 'scrollheightbutton' : ''}`}>
            {t('Join_With_Us')}
        </button>
    </Link>
  );
};

export default FloatingButton;
