import axios from "axios";
import { REGISTER, SUBSCRIPTIONLIST, VERIFYSIGNUPOTP, RESENDOTP, GOOGLE_LOGIN, CONTACT_US, ADDSUBSCRIBER, CREATE_TEMP_USER } from "../endpoints";
const api = axios.create();

// export const signup = async (values) => await api.post(REGISTER, values)
export const signup = async (values) => {
  return await api.post(REGISTER, values);
};

export const subscription_list = async (values) => {
  return await api.post(SUBSCRIPTIONLIST, values);
};

export const checkOTP = async (values) => {
  console.log("values", values);
  return await api.post(VERIFYSIGNUPOTP, values);
};
export const resendOTP = async (values) => {
  return await api.post(RESENDOTP, values);
};

export const googleSignIn = async (values) => {
  return await api.post(GOOGLE_LOGIN, values);
};

export const contactUs = async (values) => {
  return await api.post(CONTACT_US, values);
};
export const addSubscriber = async (values) => {
  return await api.post(ADDSUBSCRIBER, values);
};

export const createTempUser = async (values) => {
  return await api.post(CREATE_TEMP_USER,values)
}
