import React, { useState } from "react";
import { MailOutlined, PhoneOutlined, InstagramOutlined, TwitterOutlined, LinkedinFilled, YoutubeFilled } from "@ant-design/icons";
import LanguageDropdown from "./LanguageDropdown";
import FBlogo from "../../assets/svgs/facebook.svg";
import LanguageSelect from "components/shared/languageSelect";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { LANGUAGE_TYPES } from "constants";

// import './TopNavbar.css';

const TopNavbar = () => {
  // const [language, setLanguage] = useState('en');
  const { language } = useSelector((state) => state.configs);

  // const handleLanguageChange = (lang) => {
  //   setLanguage(lang);
  //   // document.documentElement.dir = lang === 'ar' ? 'rtl' : 'ltr';
  // };

  return (
    <div className={`wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left top-navbar" : "wrapper-right top-navbar"}`}>
      <div className="container">
        <div className="left">
          <span className={`wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left" : "wrapper-right"} email`}>
            <MailOutlined /> info@twallam.com
          </span>
          <span className={`wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left" : "wrapper-right"} phone`}>
            <PhoneOutlined />{" "}
            <a target="_blank" href="https://wa.me/966532428767" style={{ color: "white", textDecoration: "none" }}>
              {" "}
              +966532428767
            </a>
          </span>
        </div>
        <div className="right">
          {/*<LanguageDropdown onLanguageChange={handleLanguageChange} />*/}
          {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
          <LanguageSelect />
          {/*</div>*/}
          <a className={`wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left" : "wrapper-right"}`} href="https://www.instagram.com/twallamapp?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank">
            <InstagramOutlined />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61555396534809" target="_blank">
            <img src={FBlogo} alt="FBlogo" className={`wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left" : "wrapper-right"} fbLogo`} />
          </a>
          <a className={`wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left" : "wrapper-right"}`} href="https://twitter.com/gettwallam" target="_blank">
            <TwitterOutlined />
          </a>
          <a className={`wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left" : "wrapper-right"}`} href="https://www.linkedin.com/company/twallam-%D8%AA%D9%88%D9%84%D9%91%D9%85" target="_blank">
            <LinkedinFilled />
          </a>
          <a className={`wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left" : "wrapper-right"}`} href="https://www.youtube.com/channel/UCl_T36f9Qo3TYz0Ps9c_Vow" target="_blank">
            <YoutubeFilled />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopNavbar;
