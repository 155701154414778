import configs from "../../configs";

const BaseUrlAPI = configs.apiBaseUrl;

// Auth
export const REGISTER = `${BaseUrlAPI}/api/register`;
export const ATTACH_PAYMENT_METHOD = `${BaseUrlAPI}/api/users/attachPaymentMethod`;
export const SUBSCRIPTIONLIST = `${BaseUrlAPI}/api/subscriptionPackageList`;
export const TESTIMONIAL = `${BaseUrlAPI}/api/listTestimonials`;
export const VERIFYSIGNUPOTP = `${BaseUrlAPI}/api/verifySignUpOTP`;
export const RESENDOTP = `${BaseUrlAPI}/api/resendOTP`;
export const SLIDERLIST = `${BaseUrlAPI}/api/listSliders`;
export const HOMEOCRSTEPSLIST = `${BaseUrlAPI}/api/listContents`;
export const GETSECTIONDETAIL = `${BaseUrlAPI}/api/getSectionDetail`;
export const GETSECTIONS = `${BaseUrlAPI}/api/getSections`;

export const GOOGLE_LOGIN = `${BaseUrlAPI}/api/googleLogin`;
export const CREATE_TEMP_USER = `${BaseUrlAPI}/api/createTempUser`
export const LOGIN = `${BaseUrlAPI}/api/login`;
export const CONTACT_US = `${BaseUrlAPI}/api/contactUs`;
export const ADDSUBSCRIBER = `${BaseUrlAPI}/api/addSubscriber`;

// About Us Page

export const GetBannerDetail = `${BaseUrlAPI}/api/about/getSectionStepsDetail`;

export const GETAboutPageSECTIONSOne = `${BaseUrlAPI}/api/about/getSectionStepsDetail`;
export const GETAboutPageSECTIONSTwo = `${BaseUrlAPI}/api/about/getSectionStepsDetail`;

export const GETHomWeWorkData = `${BaseUrlAPI}/api/about/listHowWeWork`;
export const GETOurTeam = `${BaseUrlAPI}/api/about/listTeam`;
