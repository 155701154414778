import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Button } from "antd";
import Footer from "../../components/Footer";
import { Header } from "../../components/Header/Header";
import AccountSection from "../../components/AccountSection";
import pricingbg from "../../assets/images/pricingBottombg.png";
import { signup, subscription_list, googleSignIn } from "../../services/api/actions";
import { useNavigate, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import { BounceLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { LANGUAGE_TYPES } from "constants";
import { useTranslation } from "react-i18next";
const override = {
  borderWidth: "4px",
};
const Pricing = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.configs);

  //Get query param
  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const query_param = params.get("sn");
  const email = params.get("email");
  const pricingRef = useRef(null);

  console.log("email", email);

  useEffect(() => {
    // Scroll to the div when the component loads
    if (pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    try {
      subscription_list({})
        .then((response) => {
          console.log("pack", response.data.data);
          setPackages(response.data.data);
        })
        .catch((error) => {
          console.log("errors here");
        });
    } catch (error) {
      console.error("Error package list issue:", error);
    }
  }, [setPackages]);

  const handleClick = (product_id, type) => {
    console.log("typers ", type);
    localStorage.setItem("product_id", product_id);
    localStorage.removeItem("packages");

    if (type == 1) {
      localStorage.setItem("packages", "free");
      navigate(`/thankyou`);
    } else {
      localStorage.setItem("packages", product_id);
      navigate(`/thankyou`);
      //  navigate("/stripe", { state: { product_id } });
    }
  };
  const handleRegister = (product_id, type, package_id) => {
    localStorage.setItem("product_id", product_id);
    localStorage.removeItem("packages");

    if (email) {
      setLoading(true);
      console.log("email if", email);

      try {
        googleSignIn({ email, package_id })
          .then((response) => {
            navigate("/thankyou");
          })
          .catch((error) => {
            console.log("errors here", error);
          });
      } catch (error) {
        console.error("Error package list issue:", error);
      }
    } else {
      if (type == 1) {
        localStorage.setItem("packages", "free");
        navigate(`/signup`);
      } else {
        localStorage.setItem("packages", product_id);
        navigate(`/signup`);
      }
    }
  };

  return (
    <>
      <Header />

      <div className="pricingheader">
        <div className="pricingheadercontent">
          <p className="textcolorwhite">{t("package_pricing")}</p>
          <h1 className="textcolorwhite">
            {t("affordable_plans")} <br /> {t("for_customers")}
          </h1>
        </div>
      </div>

      {loading && (
        <div className="body-overlay">
          <BounceLoader color="#2d358c" aria-label="Loading Spinner" data-testid="loader" loading={true} size={100} cssOverride={override} />
        </div>
      )}

      <div className="container">
        <div className="TextSection">
          <h1>{t("pricing_main_title")}</h1>
          <p>{t("pricing_main_description")}</p>
        </div>
        <div className="Pricing" ref={pricingRef}>
          <p>{t("pricing_sub_title")}</p>
          <h1>
            {t("choose_the")} <span className="textcolorgreen"> {t("right_plan")}</span> {t("for_you")} <br />
            {t("your_team")}
          </h1>
          <Row gutter={[28, 28]}>
            {packages.map((pack, index) => (
              <Col xs={24} sm={24} md={12} lg={8} xl={8} key={pack.id}>
                <Card title={pack.type == 1 ? (language === LANGUAGE_TYPES.en ? pack.title : pack.title_ar) : language === LANGUAGE_TYPES.en ? pack.title : pack.title_ar} bordered={false}>
                  {/* {pack.type !== 1 && ( */}
                  <div className="billingInfo">
                    <h2>
                      SAR {pack.price} <span>/ {pack.duration == "month" ? t("month") : t("year")}</span>
                    </h2>

                    <div className="BBillText">
                      {/*<p> Billed as ${pack.duration == 'month' ? pack.price * 12 : pack.price} per year</p>*/}
                      <p>
                        {" "}
                        Billed as SAR {pack.price} <span>/ {pack.duration == "month" ? t("month") : t("year")}</span>
                      </p>
                    </div>
                  </div>
                  {/* )} */}
                  <ul className="features-list">
                    {/*<li>{pack.description}</li>*/}
                    <li className="features-list-item">{parse(language === LANGUAGE_TYPES.en ? pack.description : pack.description_ar)}</li>
                    {/*<div />*/}
                    {/* <li>Unlimited feedback</li> */}
                    {/* <li>Weekly team Feedback Friday</li>
                    <li>Custom Kudos +9 illustration</li>
                    <li>Team feedback history (30)</li>
                    <li>Personal feedback</li> */}
                  </ul>

                  {query_param === "yes" ? (
                    <div className="GetStartedBtn">
                      {/*<Link to={`/EditUser/${pack.id}`}>*/}
                      <Button type="primary" onClick={() => handleClick(pack.product_id, pack.type)}>
                        {t("get_started")}
                      </Button>
                      {/*</Link>*/}
                    </div>
                  ) : (
                    <div className="GetStartedBtn">
                      <Button type="primary" onClick={() => handleRegister(pack.product_id, pack.type, pack.id)}>
                        <a style={{ textDecoration: "none" }}>{t("choose_plan")}</a>
                      </Button>
                    </div>
                  )}
                </Card>
              </Col>
            ))}

            {/*<Col xs={24} sm={24} md={12} lg={8} xl={8}>*/}
            {/*  <Card title="Advanced" bordered={false} >*/}
            {/*    <div className="billingInfo" >*/}
            {/*      <h2 >*/}
            {/*        $9.99 <span>/ month</span>*/}
            {/*      </h2>*/}

            {/*      <div className="AdvBillText" >*/}
            {/*        <p>Billed as $199 per year</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}

            {/*    <ul className="features-list">*/}
            {/*      <li>Unlimited members</li>*/}
            {/*      <li>Unlimited feedback</li>*/}
            {/*      <li>Weekly team Feedback Friday</li>*/}
            {/*      <li>Custom Kudos +9 illustration</li>*/}
            {/*      <li>Team feedback history (30 items)</li>*/}
            {/*      <li>Personal feedback history (6 items)</li>*/}
            {/*      <li>Slack integration</li>*/}
            {/*    </ul>*/}

            {/*    <div className="GetStartedBtn">*/}
            {/*      <Button type="primary" >*/}
            {/*        Get Started*/}
            {/*      </Button>*/}
            {/*    </div>*/}
            {/*  </Card>*/}
            {/*</Col>*/}
            {/*<Col xs={24} sm={24} md={12} lg={8} xl={8}>*/}
            {/*  <Card title="Pro" bordered={false} >*/}
            {/*    <div className="billingInfo" >*/}
            {/*      <h2 >*/}
            {/*        $9.99 <span>/ month</span>*/}
            {/*      </h2>*/}

            {/*      <div className="AdvBillText" >*/}
            {/*        <p>Billed as $199 per year</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}

            {/*    <ul className="features-list">*/}
            {/*      <li>Unlimited members</li>*/}
            {/*      <li>Unlimited feedback</li>*/}
            {/*      <li>Weekly team Feedback Friday</li>*/}
            {/*      <li>Custom Kudos +9 illustration</li>*/}
            {/*      <li>Team feedback history (30 items)</li>*/}
            {/*      <li>Personal feedback history (6 items)</li>*/}
            {/*      <li>Slack integration</li>*/}
            {/*    </ul>*/}
            {/*    <div className="GetStartedBtn">*/}
            {/*      <Button type="primary" >*/}
            {/*        Get Started*/}
            {/*      </Button>*/}
            {/*    </div>*/}
            {/*  </Card>*/}
            {/*</Col>*/}
            {/*<Col xs={24} sm={24} md={12} lg={8} xl={8}>*/}
            {/*  <Card title="Basic" bordered={false} >*/}
            {/*    <div className="billingInfo" >*/}
            {/*      <h2 >*/}
            {/*        $7.99 <span>/ month</span>*/}
            {/*      </h2>*/}

            {/*      <div className="BBillText" >*/}
            {/*        <p>Billed as $96 per year</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}

            {/*    <ul className="features-list">*/}
            {/*      <li>Unlimited members</li>*/}
            {/*      <li>Unlimited feedback</li>*/}
            {/*      <li>Weekly team Feedback Friday</li>*/}
            {/*      <li>Custom Kudos +9 illustration</li>*/}
            {/*      <li>Team feedback history (30)</li>*/}
            {/*      <li>Personal feedback</li>*/}
            {/*    </ul>*/}
            {/*    <div className="GetStartedBtn">*/}
            {/*      <Button type="primary" >*/}
            {/*        Get Started*/}
            {/*      </Button>*/}
            {/*    </div>*/}
            {/*  </Card>*/}
            {/*</Col>*/}
            {/*<Col xs={24} sm={24} md={12} lg={8} xl={8}>*/}
            {/*  <Card title="Advanced" bordered={false} >*/}
            {/*    <div className="billingInfo" >*/}
            {/*      <h2 >*/}
            {/*        $9.99 <span>/ month</span>*/}
            {/*      </h2>*/}

            {/*      <div className="AdvBillText" >*/}
            {/*        <p>Billed as $199 per year</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}

            {/*    <ul className="features-list">*/}
            {/*      <li>Unlimited members</li>*/}
            {/*      <li>Unlimited feedback</li>*/}
            {/*      <li>Weekly team Feedback Friday</li>*/}
            {/*      <li>Custom Kudos +9 illustration</li>*/}
            {/*      <li>Team feedback history (30 items)</li>*/}
            {/*      <li>Personal feedback history (6 items)</li>*/}
            {/*      <li>Slack integration</li>*/}
            {/*    </ul>*/}
            {/*    <div className="GetStartedBtn">*/}
            {/*      <Button type="primary" >*/}
            {/*        Get Started*/}
            {/*      </Button>*/}
            {/*    </div>*/}
            {/*  </Card>*/}
            {/*</Col>*/}
            {/*<Col xs={24} sm={24} md={12} lg={8} xl={8}>*/}
            {/*  <Card title="Pro" bordered={false} >*/}
            {/*    <div className="billingInfo" >*/}
            {/*      <h2 >*/}
            {/*        $9.99 <span>/ month</span>*/}
            {/*      </h2>*/}

            {/*      <div className="AdvBillText" >*/}
            {/*        <p>Billed as $199 per year</p>*/}
            {/*      </div>*/}
            {/*    </div>*/}

            {/*    <ul className="features-list">*/}
            {/*      <li>Unlimited members</li>*/}
            {/*      <li>Unlimited feedback</li>*/}
            {/*      <li>Weekly team Feedback Friday</li>*/}
            {/*      <li>Custom Kudos +9 illustration</li>*/}
            {/*      <li>Team feedback history (30 items)</li>*/}
            {/*      <li>Personal feedback history (6 items)</li>*/}
            {/*      <li>Slack integration</li>*/}
            {/*    </ul>*/}
            {/*    <div className="GetStartedBtn">*/}
            {/*      <Button type="primary" >*/}
            {/*        Get Started*/}
            {/*      </Button>*/}
            {/*    </div>*/}
            {/*  </Card>*/}
            {/*</Col>*/}
          </Row>
        </div>
      </div>

      <AccountSection backgroundImage={pricingbg} />
      <Footer />
    </>
  );
};

export default Pricing;
