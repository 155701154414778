import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LANGUAGE_TYPES } from "constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { getHeadingStyle, renderHeadingForContactUs, renderHeadingForWhoWeAre, renderDefaultHeading, renderDescription } from "helpers/welcomeSectionTextExtractor";
import FloatingButton from "./FloatingButton";

const WelcomeSection = ({ imageUrl, title, title_ar, name, name_ar, description, description_ar, style, type }) => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.configs);
  const navigate = useNavigate();
  const [page, setPage] = useState();

  // Define styles dynamically
  const styles = {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.9), transparent), url(${imageUrl})`,
  };

  // Function to split the name dynamically with a check
  const splitText = (fullText) => {
    if (!fullText) return { part1: "", part2: "" };
    const splitIndex = fullText.indexOf("Became");
    if (splitIndex !== -1) {
      return {
        part1: fullText.slice(0, splitIndex),
        part2: fullText.slice(splitIndex),
      };
    }
    return { part1: fullText, part2: "" };
  };

  useEffect(() => {
    setPage(type);
  }, []);

  // Function to split Arabic text dynamically with a check
  const splitTextArabic = (fullText) => {
    if (!fullText) return { part1: "", part2: "" };
    const splitIndex = fullText.indexOf("أسرع");
    if (splitIndex !== -1) {
      return {
        part1: fullText.slice(0, splitIndex),
        part2: fullText.slice(splitIndex),
      };
    }
    return { part1: fullText, part2: "" };
  };

  // Split names dynamically
  const englishNameParts = splitText(name);
  const arabicNameParts = splitTextArabic(name_ar);

  return (
    <div className={`positionRelative ${language === LANGUAGE_TYPES.en ? "wrapper-Eng" : "wrapper-Arabic"}`}>
      <section id="WelcomeSection" style={getHeadingStyle(title, style, styles)} className={`wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left" : "wrapper-right"}`}>
        <div className={`WelcomeSection-content ${language === LANGUAGE_TYPES.en ? "content-left" : "content-right"}`}>
          <h5 className="textcolorwhite">{language === LANGUAGE_TYPES.en ? title : title_ar}</h5>

          {page == "contact" && renderHeadingForContactUs(language, name, name_ar)}

          {page == "about" && renderHeadingForWhoWeAre(language, name, name_ar)}

          {page == "home" && (
            <>
              {renderDefaultHeading(language, englishNameParts, arabicNameParts)}
              {renderDescription(language, description, description_ar)}
            </>
          )}
        </div>
      </section>
      <FloatingButton />
    </div>
  );
};

export default React.memo(WelcomeSection);
