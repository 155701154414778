import React, { useEffect, useState } from "react";
import { List } from "antd";
import { Row, Col } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import sectionImg from "../assets/images/Photo.jpg";
import { useSelector } from "react-redux";
import { LANGUAGE_TYPES } from "constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { getSectionOneDetail, getSectionTwoDetail } from "../services/api/actions/aboutPageSection";
import ourMissionBG from "assets/images/ourMission.webp";
import ourPurpose from "assets/images/ourPurpose.webp";
import OurStory from "assets/images/OurStory.webp";

const AboutBusinessSection = () => {
  const [section, setSectionDetail] = useState({});
  const [sectionTwo, setSectionTwoDetail] = useState({});
  const [sectionFour, setSectionFourDetail] = useState({});
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.configs);
  const navigate = useNavigate();

  useEffect(() => {
    getSectionDetails();
  }, []);

  const getSectionDetails = async () => {
    try {
      const sectionOneRes = await getSectionOneDetail(2);
      const sectionTwoRes = await getSectionTwoDetail(3);
      const sectionFourRes = await getSectionTwoDetail(4);
      setSectionDetail(sectionOneRes.data.data);
      setSectionTwoDetail(sectionTwoRes.data.data);
      setSectionFourDetail(sectionFourRes.data.data);
    } catch (error) {
      console.error("Error fetching section details", error);
    }
  };

  const data = [{ title: sectionTwo.step_one_title || t("Our Vision") }, { title: sectionTwo.step_two_title || t("Our Mission") }];

  const renderTitle = (title, title_ar) => {
    return language === LANGUAGE_TYPES.en ? title : title_ar;
  };

  return (
    <section id="AboutBusinessSection" className={`wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left" : "rtl"}`}>
      <div className="AboutBusinessSection container" style={{ height: "100%", gap: "5rem" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <div className="BSleft">
              <div className="BSleft-content">
                <div className="ImgContainer">
                  <img
                    //    src={section.image}
                    src={ourMissionBG}
                    alt="Business Section"
                    className="BSleftimg"
                    style={{ height: "auto" }}
                  />
                </div>

                {/* <h4 className="poppins-semibold">
                            {language === LANGUAGE_TYPES.en ? section.title : section.title_ar}
                        </h4>


                        <p className="textcolorgray">
                            {renderTitle(section.description, section.description_ar)}
                        </p>

                        <button className="InTouchbtn textcolorgreen" onClick={ () => navigate('/contact')} type="button">
                            {t('Get in Touch')}
                        </button> */}
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="BSRight" style={{ height: "100%" }}>
              <div className="BSRight-content">
                {/* <h5>{renderTitle(sectionTwo.short_description, sectionTwo.short_description_ar)}</h5> */}

                {/* <h3 className="poppins-bold">
                            {language === LANGUAGE_TYPES.en
                                ? sectionTwo.title.split(" ").map((word, index) => (
                                    <>
                                        {word}&nbsp;
                                        {(index + 1) % 4 === 0 && <br />}
                                    </>
                                ))
                                : sectionTwo.title_ar.split(" ").map((word, index) => (
                                    <>
                                        {word}&nbsp;
                                        {(index + 1) % 4 === 0 && <br />}
                                    </>
                                ))
                            }
                        </h3> */}
                <h3 className="poppins-bold text-center">{language === LANGUAGE_TYPES.en ? sectionTwo.title : sectionTwo.title_ar}</h3>

                <p className="textcolorgray">
                  {renderTitle(
                    (sectionTwo.long_description || "").split("\n\n").map((paragraph, index) => (
                      <React.Fragment key={index}>
                        {paragraph}
                        <br />
                        <br />
                      </React.Fragment>
                    )),
                    (sectionTwo.long_description_ar || "").split("\n\n").map((paragraph, index) => (
                      <React.Fragment key={index}>
                        {paragraph}
                        <br />
                        <br />
                      </React.Fragment>
                    ))
                  )}
                </p>

                {/*<List className="CustomList"*/}
                {/*    dataSource={data}*/}
                {/*    renderItem={(item, index) => (*/}
                {/*        <List.Item key={index}>*/}
                {/*            <span className="ListItemSpan">*/}
                {/*                <CheckOutlined className="CheckMark" />*/}
                {/*            </span>*/}
                {/*            <div>*/}
                {/*                <span className="ListItemtext">{renderTitle(item.title, item.title_ar)}</span>*/}
                {/*                <p>*/}
                {/*                    {index === 0*/}
                {/*                        ? renderTitle(sectionTwo.step_one_description, sectionTwo.step_one_description_ar)*/}
                {/*                        : renderTitle(sectionTwo.step_two_description, sectionTwo.step_two_description_ar)}*/}
                {/*                </p>*/}
                {/*            </div>*/}
                {/*        </List.Item>*/}
                {/*    )}*/}
                {/*/>*/}

                {/*<button className="textcolorgreen" type="button">*/}
                {/*    {t('More About Us')}*/}
                {/*</button>*/}
              </div>
            </div>
          </Col>
        </Row>

        <div className="SectionSpacer"></div>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="BSRight BSRight2nd" style={{ height: "100%" }}>
              <div className="BSRight-content">
                <h3 className="poppins-bold">{language === LANGUAGE_TYPES.en ? section.title : section.title_ar}</h3>

                <p className="textcolorgray">
                  {renderTitle(
                    (section.long_description || "").split("\n\n").map((paragraph, index) => (
                      <React.Fragment key={index}>
                        {paragraph}
                        <br />
                        <br />
                      </React.Fragment>
                    )),
                    (section.long_description_ar || "").split("\n\n").map((paragraph, index) => (
                      <React.Fragment key={index}>
                        {paragraph}
                        <br />
                        <br />
                      </React.Fragment>
                    ))
                  )}
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <div className="BSleft BSleft2nd">
              <div className="BSleft-content">
                <div className="ImgContainer">
                  <img
                    //    src={section.image}
                    src={ourPurpose}
                    alt="Business Section"
                    className="BSleftimg"
                    style={{ height: "auto" }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="SectionSpacer"></div>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <div className="BSleft BSleft3rd">
              <div className="BSleft-content">
                <div className="ImgContainer">
                  <img
                    //    src={section.image}
                    src={OurStory}
                    alt="Business Section"
                    className="BSleftimg"
                    style={{ height: "auto" }}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div className="BSRight BSRight3rd" style={{ height: "100%" }}>
              <div className="BSRight-content">
                <h3 className="poppins-bold">{language === LANGUAGE_TYPES.en ? sectionFour.title : sectionFour.title_ar}</h3>

                <p className="textcolorgray">
                  {renderTitle(
                    (sectionFour.long_description || "").split("\n\n").map((paragraph, index) => (
                      <React.Fragment key={index}>
                        {paragraph}
                        <br />
                        <br />
                      </React.Fragment>
                    )),
                    (sectionFour.long_description_ar || "").split("\n\n").map((paragraph, index) => (
                      <React.Fragment key={index}>
                        {paragraph}
                        <br />
                        <br />
                      </React.Fragment>
                    ))
                  )}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default AboutBusinessSection;
