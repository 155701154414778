import React, { useEffect, useState } from "react";
import { List } from "antd";
import { PhoneOutlined, EnvironmentOutlined, MailOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { LANGUAGE_TYPES } from "constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ContactBusiBG } from "assets/index";

const ContactBusinessSection = () => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.configs);
  const navigate = useNavigate();

  return (
    <section id="contactBusinessSection" className={`wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left" : "rtl"}`}>
      <div className="BusinessSection container " style={{ height: "100%", gap: "5rem" }}>
        <div className="BSleft">
          <div className="BSleft-content">
            <div className="ImgContainer">
              <img src={ContactBusiBG} alt={"logo"} className="BSleftimg" style={{ height: "auto" }} />
            </div>
          </div>
        </div>

        <div className="BSRight" style={{ height: "100%" }}>
          <div className="BSRight-content">
            <h5 className={language !== LANGUAGE_TYPES.en ? "arabic-text" : ""}>{t("Let’s_Talk")}</h5>

            <h3 className={`poppins-bold ${language !== LANGUAGE_TYPES.en ? "arabic-text" : ""}`}>{t("Get_in_Touch")}</h3>

            <p className={`textcolorgray ${language !== LANGUAGE_TYPES.en ? "arabic-text" : ""}`}>{t("Completely_synergize_resource")}</p>

            <List className="CustomList">
              <List.Item className="listItemConatiner">
                <span className={`ListItemSpan ${language === LANGUAGE_TYPES.ar ? "List-margin-left" : ""}`}>
                  <PhoneOutlined />
                </span>
                <span className="ListContent">
                  <h4>{t("Have_any_question?")}</h4>
                  <p>{t("Free_+01")}</p>
                </span>
              </List.Item>

              <List.Item className="listItemConatiner">
                <span className={`ListItemSpan ${language === LANGUAGE_TYPES.ar ? "List-margin-left" : ""}`}>
                  <MailOutlined />
                </span>
                <span className="ListContent">
                  <h4>{t("Write_email")}</h4>
                  <p>{t("Info@twallam.com")}</p>
                </span>
              </List.Item>

              <List.Item className="listItemConatiner">
                <span className={`ListItemSpan ${language === LANGUAGE_TYPES.ar ? "List-margin-left" : ""}`}>
                  <EnvironmentOutlined />
                </span>
                <span className="ListContent">
                  <h4>{t("Visit_anytime")}</h4>
                  <p>{t("location_address")}</p>
                </span>
              </List.Item>
            </List>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactBusinessSection;
