import React, { useEffect, useState } from "react";
import { List } from "antd";
import { Row, Col } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import sectionImg from "../assets/images/Photo.jpg";
import { useSelector } from "react-redux";
import { LANGUAGE_TYPES } from "constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { getSectionTwoDetail } from "../services/api/actions/aboutPageSection";
import ourMissionBG from "assets/images/ourMission.webp";
import ourPurpose from "assets/images/ourPurpose.webp";
import OurStory from "assets/images/OurStory.webp";
import { useParams } from "react-router-dom";

const ServiceBusinessSection = () => {
  const { type } = useParams();
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.configs);
  const navigate = useNavigate();

  const renderTitle = (title, title_ar) => {
    return language === LANGUAGE_TYPES.en ? title : title_ar;
  };

  return (
    <section id="AboutBusinessSection" className={`wrapper ${language === LANGUAGE_TYPES.en ? "wrapper-left" : "rtl"}`}>
      <div className="AboutBusinessSection container" style={{ height: "100%", gap: "5rem" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="BSRight" style={{ height: "100%" }}>
              {type === "bookKeeping" ? (
                <div className="BSRight-content bookKeepingSection">
                  <h3 className="poppins-bold text-center">{t("The Backbone of Financial Success")}</h3>

                  <p className="textcolorgray">{t("service_book_desc_one")}</p>
                  <p className="textcolorgray">{t("service_book_desc_second")}</p>

                  <h4 className="mt-5">{t("key_benefits")}:</h4>
                  <ul className="li_height">
                    <li >
                      <span className="poppins-bold">{t("book_benefit_step_one")}:</span> {t("book_benefit_step_one_description")}
                    </li>
                    <li>
                      <span className="poppins-bold">{t("book_benefit_step_two")}:</span> {t("book_benefit_step_two_description")}
                    </li>
                    <li>
                      <span className="poppins-bold">{t("book_benefit_step_three")}:</span> {t("book_benefit_step_three_description")}
                    </li>
                    <li>
                      <span className="poppins-bold">{t("book_benefit_step_four")}:</span> {t("book_benefit_step_four_description")}
                    </li>
                  </ul>

                  <h4 className="mt-5">{t("what_we_offer")}</h4>
                  <p className="textcolorgray">{t("what_we_offer_description")}:</p>
                  <ul className="li_height">
                    <li>{t("book_offer_step_one")}</li>
                    <li>{t("book_offer_step_two")}</li>
                    <li>{t("book_offer_step_three")}</li>
                    <li>{t("book_offer_step_four")}</li>
                    <li>{t("book_offer_step_five")}</li>
                    <li>{t("book_offer_step_six")}</li>
                  </ul>

                  <p className="textcolorgray">{t("book_offer_end_description")}</p>

                  <h4 className="mt-5">{t("why_choose_us")}</h4>
                  <p className="textcolorgray">{t("book_choose_description")}</p>
                </div>
              ) : type === "taxPreparation" ? (
                <div className="BSRight-content  tax_preparation">
                  <h3 className="poppins-bold text-center">{t("Effortless Tax Preparation for Businesses")}</h3>

                  <p className="textcolorgray">{t("service_tax_desc")}</p>

                  <h4  className="mt-5">{t("key_benefits")}:</h4>
                  <ul className="li_height">
                    <li>
                      <span className="poppins-bold">{t("tax_benefit_step_one")}:</span> {t("tax_benefit_step_one_description")}
                    </li>
                    <li>
                      <span className="poppins-bold">{t("tax_benefit_step_two")}:</span> {t("tax_benefit_step_two_description")}
                    </li>
                    <li>
                      <span className="poppins-bold">{t("tax_benefit_step_three")}:</span> {t("tax_benefit_step_three_description")}
                    </li>
                    <li>
                      <span className="poppins-bold">{t("tax_benefit_step_four")}:</span> {t("tax_benefit_step_four_description")}
                    </li>
                  </ul>

                  <h4  className="mt-5">{t("what_we_offer")}</h4>
                  <p className="textcolorgray">{t("tax_offer_start_description")}:</p>
                  <ul className="li_height">
                    <li>{t("tax_offer_step_one")}</li>
                    <li>{t("tax_offer_step_two")}</li>
                    <li>{t("tax_offer_step_three")}</li>
                    <li>{t("tax_offer_step_four")}</li>
                    <li>{t("tax_offer_step_five")}</li>
                    <li>{t("tax_offer_step_six")}</li>
                  </ul>

                  <p className="textcolorgray">{t("tax_offer_end_description")}</p>

                  <h4  className="mt-5">{t("why_choose_us")}</h4>
                  <p className="textcolorgray">{t("tax_choose_description")}</p>
                </div>
              ) : type === "financialConsulting" ? (
                <div className="BSRight-content  financial_consulting ">
                  <h3 className="poppins-bold text-center">{t("Strategic Financial Consulting for Smarter Business Decisions")}</h3>

                  <p className="textcolorgray">{t("service_finance_desc")}</p>

                  <h4  className="mt-5">{t("key_benefits")}:</h4>
                  <ul className="li_height">
                    <li>
                      <span className="poppins-bold">{t("finance_benefit_step_one")}:</span> {t("finance_benefit_step_one_description")}
                    </li>
                    <li>
                      <span className="poppins-bold">{t("finance_benefit_step_two")}:</span> {t("finance_benefit_step_two_description")}
                    </li>
                    <li>
                      <span className="poppins-bold">{t("finance_benefit_step_three")}:</span> {t("finance_benefit_step_three_description")}
                    </li>
                    <li>
                      <span className="poppins-bold">{t("finance_benefit_step_four")}:</span> {t("finance_benefit_step_four_description")}
                    </li>
                  </ul>

                  <h4  className="mt-5">{t("what_we_offer")}</h4>
                  <p className="textcolorgray">{t("finance_offer_start_description")}:</p>
                  <ul className="li_height">
                    <li>{t("finance_offer_step_one")}</li>
                    <li>{t("finance_offer_step_two")}</li>
                    <li>{t("finance_offer_step_three")}</li>
                    <li>{t("finance_offer_step_four")}</li>
                    <li>{t("finance_offer_step_five")}</li>
                    <li>{t("finance_offer_step_six")}</li>
                  </ul>

                  <p className="textcolorgray">{t("finance_offer_end_description")}</p>

                  <h4  className="mt-5">{t("why_choose_us")}</h4>
                  <p className="textcolorgray">{t("finance_choose_description")}</p>
                </div>
              ) : null}
            </div>
          </Col>
        </Row>

        {/* <div className="SectionSpacer"></div> */}
      </div>
    </section>
  );
};

export default ServiceBusinessSection;
