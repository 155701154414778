import React, { useEffect, useState } from "react";
import { Typography, Form, Input, Row, Col, List, Divider } from "antd";
import { EnvironmentOutlined, PhoneOutlined, MailOutlined, InstagramOutlined, TwitterOutlined, LinkedinFilled, YoutubeFilled } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"; // Import useSelector to get the current language
import { addSubscriber } from "../services/api/actions";
import FBlogo from "assets/svgs/facebook.svg";

import FooterLogo from "../assets/logos/footerlogo.png";
import FooterlogoEng from "../assets/logos/footerlogoEng.png";
import { LANGUAGE_TYPES } from "constants";
import { toast } from "react-toastify";
import TwallamLogoEng from "../assets/logos/TwallamHeaderEng.png";
import TwallamLogo from "../assets/logos/headerLogo.png";

const { Title } = Typography;

const Footer = () => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.configs); // Get the current language from state
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    console.log("Received values of form: ", values);
    console.log("in handleSubmit");
    try {
      // await form.validateFields();
      const formData = await form.validateFields();
      console.log("Received values of form: ", formData);
      // ----------------------------------------

      try {
        setLoading(true);
        addSubscriber(formData)
          .then((response) => {
            setLoading(false);
            console.log("contact us res.data", response);

            if (response.data.meta.code === 404) {
              toast.error(response.data.meta.message);
            } else {
              navigate(`/thankyou?redirect=subscribe`);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log("errors here", error);
          });
      } catch (error) {
        console.error("Error sent mail:", error);
      }

      // ----------------------------------------

      // const newData = {

      // };

      // await post("signUp", newData);
      form.resetFields();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // className={`Footer ${language === 'ar' ? 'rtl' : ''}`}
  return (
    <div className={`Footer ${language === LANGUAGE_TYPES.ar ? "rtl" : ""}`}>
      {" "}
      {/* Apply RTL class if Arabic */}
      <div className="container">
        <div className="footer-top">
          <div className="wrap">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              name="ContactUsForm"
              className="search"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              {/* <input className="searchTerm" type="text" placeholder={t("enter_email_address")} /> */}
              <Form.Item
                name="email"
                className="searchTerm"
                rules={[
                  {
                    required: true,
                    message: t("enter_your_email"),
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input className="emailInput" placeholder={t("form_email_address")} />
              </Form.Item>
              {/* <Form.Item> */}
              <button type="submit" className="searchButton">
                {t("subscribe")}
              </button>
              {/* </Form.Item> */}
            </Form>
          </div>
        </div>

        <div className="footer-middle">
          <Row gutter={16} justify="center" style={{ marginBottom: "20px" }}>
            {/* <Col xs={24} sm={12} md={12} lg={7}>
       
            </Col> */}
            <Col xs={24} sm={8} md={8} lg={8}>
              <div className="contact-box">
                <PhoneOutlined size="larger" rotate={100} />
                <div className="info">
                  <Title level={4}>{t("call_us")}</Title>
                  <p>{t("Free_phone")}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8}>
              <div className="mail-box">
                <MailOutlined />
                <div className="info">
                  <Title level={4}>{t("mail_us")}</Title>
                  <p>{t("mailDetail")}</p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8}>
              <div className="location-box">
                <EnvironmentOutlined />
                <div className="info">
                  <Title level={4}>{t("location")}</Title>
                  <p>{t("location_address")}</p>
                </div>
              </div>
            </Col>
          </Row>
          <Divider />
          <Row gutter={16}>
            <Col xs={24} sm={8} md={8} lg={8}>
              <Link to="/" className="footerBrandLogo">
                <div className="footerLogoContainer">
                  <img src={`${language === LANGUAGE_TYPES.en ? FooterlogoEng : FooterLogo}`} alt="Footer Logo" className="FooterLogo" />
                </div>
              </Link>

              <div className="footer_social_icon">
                <a href="https://www.instagram.com/twallamapp?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank">
                  <InstagramOutlined />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61555396534809" target="_blank">
                  <img src={FBlogo} alt="FBlogo" className="fbLogo" />
                </a>
                <a href="https://twitter.com/gettwallam" target="_blank">
                  <TwitterOutlined />
                </a>

                <a href="https://www.linkedin.com/company/twallam-%D8%AA%D9%88%D9%84%D9%91%D9%85" target="_blank">
                  <LinkedinFilled />
                </a>
                <a href="https://www.youtube.com/channel/UCl_T36f9Qo3TYz0Ps9c_Vow" target="_blank">
                  <YoutubeFilled />
                </a>
              </div>
            </Col>

            <Col xs={24} sm={8} md={8} lg={8}>
              <Title className="footer-middle-heading" level={5}>
                {t("quick_links")}
              </Title>
              <List size="small">
                <List.Item>
                  <Link to="/">{t("home")}</Link>
                </List.Item>
                <List.Item>
                  <Link to="/about">{t("about_us")}</Link>
                </List.Item>
                {/* <List.Item>
                  <Link to="/blog">{t("blog")}</Link>
                </List.Item>
                <List.Item>
                  <Link to="/services">{t("services")}</Link>
                </List.Item> */}
                <List.Item>
                  <Link to="/contact">{t("contact")}</Link>
                </List.Item>
              </List>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8}>
              <Title className="footer-middle-heading" level={5}>
                {t("services")}
              </Title>
              <List size="small">
                <List.Item>
                  <Link to="/services/bookKeeping">{t("Bookkeeping Services")}</Link>
                </List.Item>
                <List.Item>
                  <Link to="/services/taxPreparation">{t("Tax Preparation Services")}</Link>
                </List.Item>
                <List.Item>
                  <Link to="/services/financialConsulting">{t("Financial Consulting Services")}</Link>
                </List.Item>
                {/* <List.Item>{t("financial_consulting")}</List.Item>
                <List.Item>{t("business_management")}</List.Item>
                <List.Item>{t("consultant_programs")}</List.Item> */}
                {/* <List.Item>{t("company_management")}</List.Item>
                <List.Item>{t("insurance_finance")}</List.Item> */}
              </List>
            </Col>

            {/* <Col xs={24} sm={6} md={6} lg={6}>
              <Title className="footer-middle-heading" level={5}>
                {t("social_media")}
              </Title>
              <List size="small">
                <List.Item>{t("style_guide")}</List.Item>
                <List.Item>{t("licenses")}</List.Item>
                <List.Item>{t("instructions")}</List.Item>
                <List.Item>{t("changelog")}</List.Item>
                <List.Item>{t("404_page")}</List.Item>
              </List>
            </Col> */}
          </Row>
        </div>
        <Divider />
        <div className="footer-bottom">
          <p>
            &copy; {t("2024")}-{t("2025")} - {t("Twallam")}{" "}
            <a href="terms/conditions" target="_blank">
              {t("terms_conditions")}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
